let baseUrl = "http://thunder.pub/api";

const apiUrl = {
  // 注册
  register: `${baseUrl}/users/register`,
  // 登录
  login: `${baseUrl}/users/login`,
  // 获取用户信息
  profile: `${baseUrl}/users/profile`,
  // 用户管理
  usersManagement: `${baseUrl}/users/management`,
  // 用户封禁
  ban: `${baseUrl}/users/ban/`,
  // 用户解封
  unban: `${baseUrl}/users/unban/`,
  // 设为代理
  setAgent: `${baseUrl}/users/setAgent/`,
  // 增加余额 
  addBalance: `${baseUrl}/users/addBalance/`,
  // 设置余额
  setBalance: `${baseUrl}/users/setBalance/`,
  // 设置折扣
  setDiscount: `${baseUrl}/users/setDiscount/`,
  // 生成keys
  generateKeys: `${baseUrl}/keys/generate`,
  // 删除keys
  deleteKeys: `${baseUrl}/keys/delete/`,
  // 获取keys
  getKeys: `${baseUrl}/keys`,
  // 激活
  activate: `${baseUrl}/activate/`,
  // 重置HWID
  revokeHwid: `${baseUrl}/revokeHwid/`,
  // 补偿时长
  compensate: `${baseUrl}/compensate/`,
  // 下载Loader
  downloadLoader: `${baseUrl}/loader`,
};

export default apiUrl;
